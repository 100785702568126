import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentUser, useCurrentUserClientTimezone } from '../../../contexts/AuthContext.selectors';
import { ProfileType, useAuthorizeProfile } from '../../../hooks/useAuthorizeProfile';
import { useQueryGetGetAllMissions } from '../../../backend/gen';
import { HeaderComponent } from '../../../components/HeaderComponent/HeaderComponent';
import { formatISO } from 'date-fns';
import {
    DispatcherPageContentContainer,
    StyledCurrentTimeLine,
    StyledCurrentVisibleDayText,
    StyledDateDelimiterLine,
    StyledDateDelimiterTextContainer,
    StyledGanttContainer,
} from './DispatcherPage.style';
import { Gantt } from './Gantt/Gantt';
import { theme } from '../../../theme';
import { ganttContentPaddingLeft, ganttPxPerMs, ganttTitleWidth } from './Gantt/gantt.constants';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { defaultLocale, errorToastConfig } from '../../../utils/constants';
import { Box, CircularProgress } from '@mui/material';
import { pxToTimestamp } from './DispatcherPage.utils';
import { useGanttDimensions } from './hooks/useGanttDimensions';
import { useAutoScroll } from './hooks/useAutoscroll';
import { useVisibleDay } from './hooks/useVisibleDay';
import { useCurrentTime } from './hooks/useCurrentTime';
import { useGanttPeriod } from './hooks/useGanttPeriod';
import { GanttPeriodChangeDialog } from './GanttPeriod/GanttPeriodChangeDialog/GanttPeriodChangeDialog';
import { MissionFormDialog } from './MissionFormDialog/MissionFormDialog';
import { utcToZonedTime } from 'date-fns-tz';
import { useIntlValue } from '@innovatm/js-iam';
import { localeToDateFormat } from '../../../components/time/time.utils';
import { FormattedDate } from '../../../components/time/FormattedDate';

export const DispatcherPage = memo(function DispatcherPage() {
    const currentUser = useCurrentUser();
    const timezone = useCurrentUserClientTimezone();
    useAuthorizeProfile(currentUser, ProfileType.DISPATCHER);
    const locale = useIntlValue('locale');
    const dateFormat = localeToDateFormat[locale || defaultLocale];

    const [ganttScrollYOffset, setGanttScrollYOffset] = useState(0);

    const ganttRef = useRef<HTMLDivElement>(null);
    const currentTimeIndicatorRef = useRef<HTMLDivElement>(null);
    const currentTimeIndicatorElement = currentTimeIndicatorRef.current;

    const {
        requestFrom,
        requestTo,
        displayFrom,
        displayTo,
        setTactical,
        setCustom,
        updateDisplayRange,
        ganttPeriodDialogIsOpen,
        isTactical,
    } = useGanttPeriod();
    const { currentTimeInPx } = useCurrentTime(displayFrom);
    const { ganttWidth, dayDelimiters } = useGanttDimensions(displayFrom, displayTo);
    const { shallAutoScroll, enableAutoScroll, disableAutoScroll } = useAutoScroll(
        currentTimeIndicatorElement,
        isTactical,
    );
    const { currentVisibleDay, onGanttScroll } = useVisibleDay(dayDelimiters, displayFrom, isTactical);

    // Calculate y offset on scroll to position vertical current time line
    const ganttContainerElement = ganttRef.current;
    useEffect(() => {
        const onScroll = () => setGanttScrollYOffset(ganttRef.current.scrollTop);
        if (ganttContainerElement) {
            ganttContainerElement.addEventListener('scroll', onScroll, { passive: true });
            return () => ganttContainerElement.removeEventListener('scroll', onScroll);
        }
    }, [ganttContainerElement]);

    const { data: ganttData, status } = useQueryGetGetAllMissions(
        {
            from:
                isTactical || !requestFrom
                    ? undefined
                    : formatISO(utcToZonedTime(requestFrom, timezone), { representation: 'date' }),
            to:
                isTactical || !requestTo
                    ? undefined
                    : formatISO(utcToZonedTime(requestTo, timezone), { representation: 'date' }),
        },
        {
            onError: () => toast(<FormattedMessage id={'bus.error.getMissions'} />, errorToastConfig),
            refetchInterval: 4000,
        },
    );

    const allBusNames = useMemo(() => {
        return ganttData?.buses?.map(bus => bus.name);
    }, [ganttData?.buses]);

    const timeRangeKey = useMemo(() => {
        if (ganttData?.from && ganttData?.to) {
            return `${ganttData.from}-${ganttData.to}`;
        }
        return null;
    }, [ganttData?.from, ganttData?.to]);

    useEffect(() => {
        if (timeRangeKey && ganttData) {
            updateDisplayRange(ganttData.from, ganttData.to);
        }
    }, [timeRangeKey, ganttData, updateDisplayRange]);

    if (status === 'error' || !ganttData) {
        return (
            <DispatcherPageContentContainer>
                <HeaderComponent isDispatcher={true} />
                <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress color={'error'} />
                </Box>
            </DispatcherPageContentContainer>
        );
    }
    return (
        <>
            <HeaderComponent
                isDispatcher={true}
                from={displayFrom}
                to={displayTo}
                onClickClock={shallAutoScroll ? disableAutoScroll : enableAutoScroll}
                setGanttPeriodDialogOpen={ganttPeriodDialogIsOpen.setTrue}
            />
            <DispatcherPageContentContainer ref={ganttRef} onScroll={onGanttScroll}>
                <StyledDateDelimiterTextContainer ganttwidth={ganttWidth}>
                    <StyledCurrentVisibleDayText>{currentVisibleDay}</StyledCurrentVisibleDayText>
                    {dayDelimiters.map((left, index) => {
                        const timestamp = pxToTimestamp(
                            left,
                            displayFrom,
                            ganttPxPerMs,
                            ganttTitleWidth,
                            ganttContentPaddingLeft,
                        );
                        return (
                            <Box position={'absolute'} left={`${left - 25}px`} key={index}>
                                <FormattedDate format={dateFormat}>{timestamp}</FormattedDate>
                            </Box>
                        );
                    })}
                </StyledDateDelimiterTextContainer>
                {dayDelimiters.map((left, index) => (
                    <StyledDateDelimiterLine key={index} left={`${left}px`} top={`${ganttScrollYOffset}px`} />
                ))}
                <GanttPeriodChangeDialog
                    open={ganttPeriodDialogIsOpen.value}
                    closeDialog={ganttPeriodDialogIsOpen.setFalse}
                    from={displayFrom}
                    to={displayTo}
                    setTactical={setTactical}
                    setCustom={setCustom}
                    currentOpsDay={ganttData?.currentDay}
                />
                <MissionFormDialog flights={ganttData.flights} currentOpsDay={ganttData?.currentDay} />
                {isTactical && (
                    <StyledCurrentTimeLine
                        left={`${currentTimeInPx}px`}
                        top={`${ganttScrollYOffset}px`}
                        ref={currentTimeIndicatorRef}
                    />
                )}
                <StyledGanttContainer>
                    <Gantt
                        buses={allBusNames}
                        missionData={ganttData.unallocated}
                        from={displayFrom}
                        to={displayTo}
                        isUnallocated={true}
                        titleBgColor={theme.palette.blue.lightBg}
                    />
                </StyledGanttContainer>
                {ganttData.buses.map((bus, i) => {
                    return (
                        <StyledGanttContainer
                            key={i}
                            bgcolor={'#245F88'}
                            sx={{
                                marginBottom: i === ganttData.buses.length - 1 ? 0 : '10px',
                            }}
                        >
                            <Gantt
                                buses={allBusNames}
                                missionData={
                                    ganttData.allocations.find(allocations => allocations.bus === bus.name)?.missions
                                }
                                from={displayFrom}
                                to={displayTo}
                                bus={bus}
                                titleBgColor={theme.palette.primary.main}
                            />
                        </StyledGanttContainer>
                    );
                })}
            </DispatcherPageContentContainer>
        </>
    );
});
