/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import React, { useCallback } from 'react';
import { DialogContent, Input, styled } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { TimeField } from '../../../../../components/form/TimeField/TimeField';
import { theme } from '../../../../../theme';
import { VacationPeriodType } from './BusDefineVacationDialog';

interface BusDefineVacationDialogContentProps {
    period: VacationPeriodType;
    setPeriod: React.Dispatch<React.SetStateAction<VacationPeriodType>>;
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
}

export function BusDefineVacationDialogContent({
    period,
    setPeriod,
    name,
    setName,
    comment,
    setComment,
}: BusDefineVacationDialogContentProps) {
    const intl = useIntl();

    const onChangeUpdatePeriodStart = useCallback(
        newStartTime => setPeriod({ ...period, start: newStartTime }),
        [period, setPeriod],
    );
    const onChangeUpdatePeriodEnd = useCallback(
        newEndTime => setPeriod({ ...period, end: newEndTime }),
        [period, setPeriod],
    );

    return (
        <DialogContent>
            <StyledVacationFormInputContainer>
                <span>
                    <FormattedMessage id={'dispatcher.busDefineVacation.namePlaceholder'} />
                </span>
                <StyledVacationFormInput
                    placeholder={intl.formatMessage({ id: 'dispatcher.busDefineVacation.namePlaceholder' })}
                    defaultValue={name}
                    onChange={event => {
                        setName(event.target.value);
                    }}
                    required={true}
                    disableUnderline
                />
            </StyledVacationFormInputContainer>

            <StyledVacationFormInputContainer>
                <span>
                    <FormattedMessage id={'dispatcher.busDefineVacation.commentPlaceholder'} />
                </span>
                <StyledVacationFormInput
                    placeholder={intl.formatMessage({ id: 'dispatcher.busDefineVacation.commentPlaceholder' })}
                    defaultValue={comment}
                    onChange={event => {
                        setComment(event.target.value);
                    }}
                    disableUnderline
                />
            </StyledVacationFormInputContainer>

            <StyledPeriodContainer>
                <StyledDefineVacationPeriodTimePicker
                    name={'periodStartTime'}
                    date={period.start ? period.start : new Date()}
                    value={period.start}
                    onChange={onChangeUpdatePeriodStart}
                    label={
                        <span className={'vacationTimeLabelText'}>
                            <FormattedMessage id={'dispatcher.busDefineVacation.fromTime'} />
                        </span>
                    }
                    required={true}
                />

                <StyledDefineVacationPeriodTimePicker
                    name={'periodEndTime'}
                    date={period.end ? period.end : new Date()}
                    value={period.end}
                    onChange={onChangeUpdatePeriodEnd}
                    label={
                        <span className={'vacationTimeLabelText'}>
                            <FormattedMessage id={'dispatcher.busDefineVacation.toTime'} />
                        </span>
                    }
                    required={true}
                />
            </StyledPeriodContainer>
        </DialogContent>
    );
}

const StyledVacationFormInputContainer = styled('div')`
    height: 75px;
    position: relative;
    display: flex;
    align-items: end;
    margin-bottom: 20px;
    & > span {
        position: absolute;
        top: 0;
        left: 1px;
        font-size: 14px;
        font-weight: normal;
    }
`;

const StyledVacationFormInput = styled(Input)`
    width: 100%;
    height: 50px;
    border-radius: 5px;
    padding: 10px;
    background-color: #f1f7fb;
`;

const StyledPeriodContainer = styled('div')`
    display: flex;
    gap: 40px;
    & .vacationTimeLabelText {
        color: ${theme.palette.blue.darkest};
    }
`;

const StyledDefineVacationPeriodTimePicker = styled(TimeField)`
    margin-top: 5px;
    & .MuiFilledInput-root {
        background-color: #f1f7fb !important;
    }
    & .MuiInputBase-input {
        color: ${theme.palette.blue.darkest} !important;
        ::-webkit-calendar-picker-indicator {
            filter: invert(0) !important;
        }
    }
`;
