import React, { memo } from 'react';
import {
    StyledBusUnavailabilityPeriodDatePicker,
    StyledBusUnavailabilityPeriodDialogContentContainer,
    StyledBusUnavailabilityPeriodTimePicker,
} from './BusUnavailabilityPeriodDialogContent.style';
import { FormattedMessage } from 'react-intl';
import { UnavailabilityPeriodType } from '../../BusUnavailabilityPeriodDialog';
import { Box, DialogContent } from '@mui/material';
import { UnavailabilityPeriodInfo } from './UnavailabilityPeriodInfo';

export const BusUnavailabilityPeriodDialogContent = memo(function BusUnavailabilityPeriodDialogContent({
    period,
    setPeriod,
}: {
    period: UnavailabilityPeriodType;
    setPeriod: React.Dispatch<React.SetStateAction<UnavailabilityPeriodType>>;
}) {
    const today = new Date();

    return (
        <DialogContent>
            <StyledBusUnavailabilityPeriodDialogContentContainer>
                <Box>
                    <StyledBusUnavailabilityPeriodDatePicker
                        value={period.startDate}
                        onChange={e => setPeriod({ ...period, startDate: e?.getTime() })}
                        minDate={today}
                        maxDate={period.endDate ? new Date(period.endDate) : undefined}
                    />
                    <StyledBusUnavailabilityPeriodTimePicker
                        name={'periodStartTime'}
                        date={period.startDate ? period.startDate : today}
                        value={period.startTime}
                        onChange={e => setPeriod({ ...period, startTime: e })}
                    />
                </Box>
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.separatorText'} />
                <Box>
                    <StyledBusUnavailabilityPeriodDatePicker
                        value={period.endDate}
                        onChange={e => setPeriod({ ...period, endDate: e?.getTime() })}
                        minDate={period.startDate ? new Date(period.startDate) : today}
                    />
                    <StyledBusUnavailabilityPeriodTimePicker
                        name={'periodEndTime'}
                        date={period.endDate ? period.endDate : period.startDate ? period.startDate : today}
                        value={period.endTime}
                        onChange={e => setPeriod({ ...period, endTime: e })}
                    />
                </Box>
            </StyledBusUnavailabilityPeriodDialogContentContainer>
            <UnavailabilityPeriodInfo period={period} />
        </DialogContent>
    );
});
