import React, { memo, useCallback } from 'react';
import { UnavailabilityPeriodType } from '../../BusUnavailabilityPeriodDialog';
import { StyledInfoIcon, StyledUnavailabilityPeriodInfo } from './UnavailabilityPeriodInfo.style';
import { FormattedMessage } from 'react-intl';

export const UnavailabilityPeriodInfo = memo(function UnavailabilityPeriodInfo({
    period,
}: {
    period: UnavailabilityPeriodType;
}) {
    const getFullTimestamp = useCallback((date: number | null, time: number | null) => {
        if (!date || !time) return null;
        const fullDate = new Date(date);
        const timeDate = new Date(time);
        fullDate.setHours(timeDate.getHours(), timeDate.getMinutes());
        return fullDate.getTime();
    }, []);

    const hasStartInfo = !!(period.startDate && period.startTime);
    const hasEndInfo = !!(period.endDate && period.endTime);

    if (!hasStartInfo && !hasEndInfo) {
        // VALID -> Bus will become available
        return (
            <StyledUnavailabilityPeriodInfo>
                <StyledInfoIcon />
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.busAvailableInfo'} />
            </StyledUnavailabilityPeriodInfo>
        );
    } else if ((!hasStartInfo && hasEndInfo) || (hasStartInfo && !hasEndInfo)) {
        // INVALID -> Missing either start or end information
        return (
            <StyledUnavailabilityPeriodInfo className="invalid">
                <StyledInfoIcon />
                <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.missingDateInfo'} />
            </StyledUnavailabilityPeriodInfo>
        );
    } else {
        const startTimestamp = getFullTimestamp(period.startDate, period.startTime);
        const endTimestamp = getFullTimestamp(period.endDate, period.endTime);

        if (startTimestamp && endTimestamp && startTimestamp >= endTimestamp) {
            // INVALID -> Dates/times are incoherent
            return (
                <StyledUnavailabilityPeriodInfo className="invalid">
                    <StyledInfoIcon />
                    <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.datesIncoherent'} />
                </StyledUnavailabilityPeriodInfo>
            );
        } else {
            // VALID -> Bus will become unavailable & flights in period will be unallocated
            return (
                <StyledUnavailabilityPeriodInfo>
                    <StyledInfoIcon />
                    <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.flightsUnallocationInfo'} />
                </StyledUnavailabilityPeriodInfo>
            );
        }
    }
});
