import { Box, styled } from '@mui/material';
import { BUS_DISPLAY_FOOTER_HEIGHT } from '../busDisplay.utils';

export const StyledBusDisplayFooterContainer = styled(Box)`
    padding-right: 60px;
    background-color: white;
    width: 100%;
    height: ${BUS_DISPLAY_FOOTER_HEIGHT}px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: #929297;
`;
