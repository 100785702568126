import React, { memo, useCallback, useMemo } from 'react';
import { Button, DialogActions } from '@mui/material';
import { CancellationButtonProps, ConfirmationButtonProps } from './BusUnavailabilityPeriodDialogActions.style';
import { FormattedMessage } from 'react-intl';
import { UnavailabilityPeriodType } from '../../BusUnavailabilityPeriodDialog';
import {
    DispatcherBusDto,
    KeyGetGetAllMissions,
    useMutationDeleteRemoveBusUnavailability,
    useMutationPostDeclareBusUnavailability,
} from '../../../../../../../backend/gen';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../../../../utils/constants';
import { useQueryClient } from 'react-query';

export const BusUnavailabilityPeriodDialogActions = memo(function BusUnavailabilityPeriodDialogActions({
    closeDialog,
    period,
    setPeriod,
    bus,
}: {
    closeDialog: () => void;
    period: UnavailabilityPeriodType;
    setPeriod: React.Dispatch<React.SetStateAction<UnavailabilityPeriodType>>;
    bus: DispatcherBusDto;
}) {
    const queryClient = useQueryClient();
    const { mutateAsync: declareBusUnavailability } = useMutationPostDeclareBusUnavailability();
    const { mutateAsync: removeBusUnavailability } = useMutationDeleteRemoveBusUnavailability();

    const getFullTimestamp = useCallback((date: number | null, time: number | null) => {
        if (!date || !time) return null;
        const fullDate = new Date(date);
        const timeDate = new Date(time);
        fullDate.setHours(timeDate.getHours(), timeDate.getMinutes());
        return fullDate.getTime();
    }, []);

    const isConfirmDisabled = useMemo(() => {
        const startTimestamp = getFullTimestamp(period.startDate, period.startTime);
        const endTimestamp = getFullTimestamp(period.endDate, period.endTime);

        const hasPartialDates = Boolean(
            ((period.startDate || period.startTime) && !(period.startDate && period.startTime)) ||
                ((period.endDate || period.endTime) && !(period.endDate && period.endTime)),
        );

        return hasPartialDates || (startTimestamp && endTimestamp && startTimestamp >= endTimestamp);
    }, [period, getFullTimestamp]);

    const onClickModifyBusUnavailability = useCallback(() => {
        if (period.startDate && period.endDate && period.startTime && period.endTime) {
            const startTimestamp = getFullTimestamp(period.startDate, period.startTime);
            const endTimestamp = getFullTimestamp(period.endDate, period.endTime);

            if (startTimestamp && endTimestamp) {
                declareBusUnavailability({
                    unavailabilityUpdateDto: {
                        id: bus?.unavailability?.id || null,
                        bus: bus.name,
                        start: startTimestamp,
                        end: endTimestamp,
                    },
                })
                    .then(() => {
                        closeDialog();
                        queryClient.invalidateQueries(KeyGetGetAllMissions);
                    })
                    .catch(() =>
                        toast(
                            <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.requestFailed'} />,
                            errorToastConfig,
                        ),
                    );
            }
        } else if (!period.startDate && !period.endDate && bus?.unavailability) {
            removeBusUnavailability({ id: bus.unavailability?.id })
                .then(() => {
                    closeDialog();
                    queryClient.invalidateQueries(KeyGetGetAllMissions);
                })
                .catch(() =>
                    toast(
                        <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.requestFailed'} />,
                        errorToastConfig,
                    ),
                );
        } else {
            closeDialog();
        }
    }, [
        bus.name,
        bus.unavailability,
        closeDialog,
        declareBusUnavailability,
        getFullTimestamp,
        period.endDate,
        period.endTime,
        period.startDate,
        period.startTime,
        queryClient,
        removeBusUnavailability,
    ]);

    const onClickClearPeriod = useCallback(() => {
        setPeriod({ startDate: null, startTime: null, endDate: null, endTime: null });
    }, [setPeriod]);

    const onClickCloseDialog = useCallback(() => {
        closeDialog();
    }, [closeDialog]);

    return (
        <DialogActions>
            <Button {...CancellationButtonProps} onClick={onClickCloseDialog}>
                <FormattedMessage id="confirm.default.cancel" />
            </Button>
            <Button {...CancellationButtonProps} onClick={onClickClearPeriod}>
                <FormattedMessage id="dispatcher.busUnavailabilityPeriod.clearPeriod" />
            </Button>
            <Button {...ConfirmationButtonProps} disabled={isConfirmDisabled} onClick={onClickModifyBusUnavailability}>
                <FormattedMessage id="confirm.default.confirm" />
            </Button>
        </DialogActions>
    );
});
