import { Box, styled } from '@mui/material';
import { BUS_DISPLAY_FOOTER_HEIGHT, BUS_DISPLAY_HEADER_HEIGHT } from './busDisplay.utils';

export const StyledBusDisplayContainer = styled(Box)`
    width: 1920px;
    height: 100%;
    position: relative;
    font-family: 'Helvetica Neue', sans-serif;
`;

export const StyledBusDisplayContentContainer = styled(Box)`
    height: calc(100% - ${BUS_DISPLAY_HEADER_HEIGHT + BUS_DISPLAY_FOOTER_HEIGHT}px);
    background-color: #1d569f;
`;

export const StyledNoActiveFlight = styled(Box)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 150px;
`;
