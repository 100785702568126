/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { GanttPeriodChangeDialogActions } from './GanttPeriodChangeDialogActions';
import React, { useCallback, useMemo, useState } from 'react';
import { GanttPeriodChangeDialogContent } from './GanttPeriodChangeDialogContent';
import { FormattedMessage } from 'react-intl';
import { Box, styled } from '@mui/material';
import { theme } from '../../../../../theme';
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/Icon-Information.svg';
import { endOfDay, parseISO, startOfDay, subDays } from 'date-fns';
import { useCurrentUserClient, useCurrentUserClientTimezone } from '../../../../../contexts/AuthContext.selectors';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

interface GanttPeriodChangeDialogContentAndActionsContainerProps {
    closeDialog: () => void;
    from: number;
    to: number;
    setTactical: () => void;
    setCustom: (from: number, to: number) => void;
    currentOpsDay?: string;
}

export function GanttPeriodChangeDialogContentAndActionsContainer({
    closeDialog,
    from,
    to,
    setTactical,
    setCustom,
    currentOpsDay,
}: GanttPeriodChangeDialogContentAndActionsContainerProps) {
    const currentUserClient = useCurrentUserClient();
    const timezone = useCurrentUserClientTimezone();
    // Since startDayHourShift can make it so that the Gantt to timestamp is situated on day postOpsTo + 1,
    // we subtract it from the to date to be able to show the real user requested from-to
    const clientStartDayShiftInMs = currentUserClient?.startDayHourShift
        ? currentUserClient.startDayHourShift * (1000 * 60 * 60) + 1
        : 0;

    const [formFrom, setFormFrom] = useState<number>(from);
    const [formTo, setFormTo] = useState<number>(to);
    const [today] = useState(() => {
        const baseToday = utcToZonedTime(new Date(), timezone || 'UTC');
        if (!currentOpsDay) return baseToday;

        const opsDate = parseISO(currentOpsDay);
        const zonedOpsDate = utcToZonedTime(opsDate, timezone || 'UTC');

        if (baseToday.getTime() > zonedOpsDate.getTime() + clientStartDayShiftInMs) {
            return zonedOpsDate;
        }
        return baseToday;
    });

    const onClickSetTactical = useCallback(() => {
        setTactical();
        closeDialog();
    }, [closeDialog, setTactical]);

    const onSubmitSetCustom = useCallback(() => {
        const zonedToday = startOfDay(today);
        const operationalDate = currentOpsDay ? parseISO(currentOpsDay) : zonedToday;
        const utcOperationalDate = zonedTimeToUtc(operationalDate, timezone || 'UTC');
        const zonedYesterday = subDays(operationalDate, 1);
        const zonedFrom = utcToZonedTime(formFrom, timezone || 'UTC');
        const fromValue = (zonedFrom >= startOfDay(zonedToday) ? zonedYesterday : zonedFrom).getTime();

        if (formTo > utcOperationalDate.getTime()) {
            const utcYesterday = zonedTimeToUtc(endOfDay(zonedYesterday), timezone || 'UTC');
            setCustom(fromValue, utcYesterday.getTime());
        } else {
            setCustom(fromValue, formTo - clientStartDayShiftInMs);
        }
        closeDialog();
    }, [today, timezone, formTo, closeDialog, setCustom, formFrom, clientStartDayShiftInMs, currentOpsDay]);

    const datesIncoherent = useMemo(() => {
        return formFrom > formTo || !formFrom || !formTo;
    }, [formFrom, formTo]);

    return (
        <>
            <GanttPeriodChangeDialogContent
                formFrom={formFrom}
                formTo={formTo}
                setFormFrom={setFormFrom}
                setFormTo={setFormTo}
                onClickSetTactical={onClickSetTactical}
                clientTimezoneDay={today}
                currentOpsDay={currentOpsDay}
            />
            <GanttPeriodChangeDialogActions
                closeDialog={closeDialog}
                onSubmitSetCustom={onSubmitSetCustom}
                disabled={datesIncoherent}
            />
            {datesIncoherent && (
                <StyledPeriodErrorInfo className="invalid">
                    <StyledInfoIcon />
                    <FormattedMessage id={'dispatcher.ganttPeriod.incoherentDates'} />
                </StyledPeriodErrorInfo>
            )}
        </>
    );
}

const StyledPeriodErrorInfo = styled(Box)`
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &.invalid {
        color: ${theme.palette.error.main};
        svg {
            stroke: ${theme.palette.error.main};
        }
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    stroke: gray;
    width: 30px;
    height: 30px;
`;
