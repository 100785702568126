/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useMissionForm } from '../../../../contexts/MissionFormContext';
import React from 'react';
import { CustomDialog } from '../../../../components/CustomDialog/CustomDialog';
import { dialogActionsClasses, dialogContentClasses, DialogTitleProps } from '@mui/material';
import { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { FlightDto } from '../../../../backend/gen';
import { MissionFormDialogContentAndActionsContainer } from './MissionFormDialogContentAndActionsContainer';
import { FormattedMessage } from 'react-intl';

export function MissionFormDialog({ flights, currentOpsDay }: { flights?: Array<FlightDto>; currentOpsDay?: string }) {
    const { currentFormValues } = useMissionForm();

    if (!currentFormValues) return null;

    const formTypeKey =
        currentFormValues.type === 'otherMissionForm'
            ? currentFormValues.mission?.type === 'RECURRENT'
                ? 'recurrentMissionForm'
                : 'otherMissionForm'
            : 'flightMissionForm';

    return (
        <CustomDialog
            open={currentFormValues.isDialogOpen}
            title={
                currentFormValues.isCreating ? (
                    <FormattedMessage id={`${formTypeKey}.title.create`} />
                ) : (
                    <FormattedMessage id={`${formTypeKey}.title.update`} />
                )
            }
            customDialogProps={MissionDialogDialogProps}
            customTitleProps={MissionDialogTitleProps}
            contentAndActionsCombined={
                <MissionFormDialogContentAndActionsContainer flights={flights} currentOpsDay={currentOpsDay} />
            }
        />
    );
}

const MissionDialogTitleProps: Partial<DialogTitleProps> = {
    color: '#082940',
    sx: { textAlign: 'center', fontFamily: 'Roboto', fontSize: '18px', fontWeight: 'bold' },
};

const MissionDialogDialogProps: Partial<DialogProps> = {
    sx: {
        [`& .${dialogClasses.paper}`]: {
            background: 'white',
            padding: '25px 70px 50px 70px',
            height: 'fit-content',
            width: '600px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 3px 75px 0 rgba(0,0,0,0.5)',
            color: '#082940',
            fontFamily: 'Roboto',
            fontSize: '18px',
        },
        [`& .${dialogContentClasses.root}`]: {
            padding: '0 0 20px 0',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        [`& .${dialogActionsClasses.root}`]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
};
