import { Box, styled } from '@mui/material';

export const StyledBusDisplayContentArrContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 60px;
    color: white;
`;

export const StyledLeftSideContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const StyledAirlineBox = styled(Box)`
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 55px;
    letter-spacing: 5px;
    img {
        max-width: 300px;
        max-height: 120px;
    }
`;

export const StyledAirportBox = styled(Box)`
    font-weight: bold;
    font-size: 100px;
`;

export const StyledRightSideContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 60px;
`;
