/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useCallback, useEffect, useState } from 'react';
import { ganttContentPaddingLeft, ganttPxPerMs, ganttTitleWidth } from '../Gantt/gantt.constants';
import { pxToTimestamp } from '../DispatcherPage.utils';
import { useFormattedDate } from '../../../../components/time/useFormattedDate';

export const useVisibleDay = (dayDelimiters: number[], from: number, isTactical: boolean) => {
    const [currentVisibleDay, setCurrentVisibleDay] = useState<string>('');
    const formatDate = useFormattedDate();

    const onGanttScroll = useCallback(
        (event: React.UIEvent<HTMLDivElement>) => {
            const scrollLeft = (event.target as HTMLDivElement).scrollLeft;

            for (let i = dayDelimiters.length - 1; i >= 0; i--) {
                if (scrollLeft + ganttTitleWidth + 50 >= dayDelimiters[i]) {
                    const timestamp = pxToTimestamp(
                        dayDelimiters[i],
                        from,
                        ganttPxPerMs,
                        ganttTitleWidth,
                        ganttContentPaddingLeft,
                    );
                    const date = formatDate(timestamp);
                    setCurrentVisibleDay(date);
                    break;
                }
            }
        },
        [dayDelimiters, formatDate, from],
    );

    useEffect(() => {
        // Set initial visible day
        if (isTactical) {
            const currentDayTimestamp = Date.now();
            setCurrentVisibleDay(formatDate(currentDayTimestamp));
        } else if (dayDelimiters.length > 0) {
            const initialTimestamp = pxToTimestamp(
                dayDelimiters[0],
                from,
                ganttPxPerMs,
                ganttTitleWidth,
                ganttContentPaddingLeft,
            );
            setCurrentVisibleDay(formatDate(initialTimestamp));
        }
    }, [dayDelimiters, formatDate, from, isTactical]);

    return { currentVisibleDay, onGanttScroll };
};
