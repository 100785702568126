/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { DispatcherBusDto } from '../../../../../backend/gen';
import { FormattedMessage } from 'react-intl';
import { CustomDialog } from '../../../../../components/CustomDialog/CustomDialog';
import React from 'react';
import { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { dialogActionsClasses, dialogContentClasses, DialogTitleProps } from '@mui/material';
import { BusDefineVacationDialogContentAndActionsContainer } from './BusDefineVacationDialogContentAndActionsContainer';

export type VacationPeriodType = {
    start?: number;
    end?: number;
};

export function BusDefineVacationDialog({
    bus,
    open,
    closeDialog,
    vacationId,
}: {
    bus: DispatcherBusDto;
    open: boolean;
    closeDialog: () => void;
    vacationId?: number;
}) {
    const vacation = bus.vacations?.find(v => v.id === vacationId);
    return (
        <CustomDialog
            open={open}
            customDialogProps={BusDefineVacationDialogProps}
            title={
                <FormattedMessage
                    id={'dispatcher.busDefineVacation.title'}
                    values={{
                        action: vacation ? 'edit' : 'new',
                        busName: bus.name.toUpperCase(),
                        br: <br />,
                    }}
                />
            }
            customTitleProps={BusDefineVacationDialogTitleProps}
            contentAndActionsCombined={
                <BusDefineVacationDialogContentAndActionsContainer
                    closeDialog={closeDialog}
                    bus={bus}
                    vacation={vacation}
                />
            }
        ></CustomDialog>
    );
}

const BusDefineVacationDialogTitleProps: Partial<DialogTitleProps> = {
    color: '#082940',
    sx: { textAlign: 'center', fontFamily: 'Roboto', fontSize: '18px', lineHeight: '28px' },
};

const BusDefineVacationDialogProps: Partial<DialogProps> = {
    sx: {
        [`& .${dialogClasses.paper}`]: {
            background: 'white',
            padding: '24px 70px 50px 70px',
            height: 'fit-content',
            width: '580px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 3px 75px 0 rgba(0,0,0,0.5)',
            color: '#082940',
            fontFamily: 'Roboto',
            fontSize: '18px',
        },
        [`& .${dialogContentClasses.root}`]: {
            padding: '0 0 20px 0',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        [`& .${dialogActionsClasses.root}`]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
};
