/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { CustomDialog } from '../../../../../components/CustomDialog/CustomDialog';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { dialogActionsClasses, dialogContentClasses, DialogTitleProps } from '@mui/material';
import { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { GanttPeriodChangeDialogContentAndActionsContainer } from './GanttPeriodChangeDialogContentAndActionsContainer';

interface GanttPeriodChangeDialogProps {
    open: boolean;
    closeDialog: () => void;
    from: number;
    to: number;
    setTactical: () => void;
    setCustom: (from: number, to: number) => void;
    currentOpsDay?: string;
}

export function GanttPeriodChangeDialog({
    open,
    closeDialog,
    from,
    to,
    setTactical,
    setCustom,
    currentOpsDay,
}: GanttPeriodChangeDialogProps) {
    return (
        <CustomDialog
            open={open}
            title={<FormattedMessage id={'dispatcher.ganttPeriod.dialogTitle'} />}
            customDialogProps={GanttPeriodChangeDialogDialogProps}
            customTitleProps={GanttPeriodChangeDialogTitleProps}
            contentAndActionsCombined={
                <GanttPeriodChangeDialogContentAndActionsContainer
                    closeDialog={closeDialog}
                    from={from}
                    to={to}
                    setTactical={setTactical}
                    setCustom={setCustom}
                    currentOpsDay={currentOpsDay}
                />
            }
        />
    );
}

const GanttPeriodChangeDialogTitleProps: Partial<DialogTitleProps> = {
    color: '#082940',
    sx: { textAlign: 'center', fontFamily: 'Roboto', fontSize: '18px', fontWeight: 'bold' },
};

const GanttPeriodChangeDialogDialogProps: Partial<DialogProps> = {
    sx: {
        [`& .${dialogClasses.paper}`]: {
            background: 'white',
            padding: '24px 70px 50px 70px',
            height: 'fit-content',
            width: '500px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 3px 75px 0 rgba(0,0,0,0.5)',
            color: '#082940',
            fontFamily: 'Roboto',
            fontSize: '18px',
        },
        [`& .${dialogContentClasses.root}`]: {
            padding: '0 0 20px 0',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        [`& .${dialogActionsClasses.root}`]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
};
