/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { FlightDto } from '../../../../backend/gen';
import { useMissionForm } from '../../../../contexts/MissionFormContext';
import React, { useCallback } from 'react';
import { FlightMissionDialogContent } from './MissionFormComponents/FlightMissionForm/FlightMissionDialogContent';
import { FlightMissionDialogActions } from './MissionFormComponents/FlightMissionForm/FlightMissionDialogActions';
import { OtherMissionDialogContent } from './MissionFormComponents/OtherMissionForm/OtherMissionDialogContent';
import { OtherMissionDialogActions } from './MissionFormComponents/OtherMissionForm/OtherMissionDialogActions';
import { ButtonProps } from '@mui/material/Button';
import { theme } from '../../../../theme';
import { buttonClasses } from '@mui/material';

export function MissionFormDialogContentAndActionsContainer({
    flights,
    currentOpsDay,
}: {
    flights?: Array<FlightDto>;
    currentOpsDay?: string;
}) {
    const { currentFormValues, closeDialog } = useMissionForm();

    const close = useCallback(() => closeDialog(currentFormValues.type), [closeDialog, currentFormValues.type]);

    if (currentFormValues.type === 'flightMissionForm')
        return (
            <>
                <FlightMissionDialogContent
                    flights={flights}
                    mission={currentFormValues.mission}
                    currentOpsDay={currentOpsDay}
                />
                <FlightMissionDialogActions
                    closeDialog={close}
                    mission={currentFormValues.mission}
                    isCreating={currentFormValues.isCreating}
                />
            </>
        );

    if (currentFormValues.type === 'otherMissionForm')
        return (
            <>
                <OtherMissionDialogContent mission={currentFormValues.mission} />
                <OtherMissionDialogActions
                    closeDialog={close}
                    mission={currentFormValues.mission}
                    isCreating={currentFormValues.isCreating}
                />
            </>
        );

    return null;
}

const MissionDialogProps: Partial<ButtonProps> = {
    size: 'medium',
    sx: {
        borderRadius: '25px',
        padding: '16px 30px',
        letterSpacing: '0',
        textTransform: 'none',
        height: '50px',
        fontSize: '15px',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        [`&.${buttonClasses.text}`]: {
            border: '1px solid rgba(12, 69, 108, 0.5)',
        },
        [`&.${buttonClasses.outlined}`]: {
            color: theme => theme.palette.blue.lightest,
            borderColor: '#245f88',
        },
    },
};

export const MissionFormDialogCancellationButtonProps: Partial<ButtonProps> = {
    ...MissionDialogProps,
    sx: {
        ...MissionDialogProps.sx,
        color: theme.palette.blue.dark,
    },
    variant: 'text',
};
