/*
 * Copyright Innov'ATM all rights reserved.
 * This software is the property of Innov'ATM and may not be used in any manner except under a
 * license agreement signed with Innov'ATM.
 */

import { useCallback, useEffect, useState } from 'react';

export const useAutoScroll = (currentTimeIndicatorElement: HTMLDivElement, isTactical: boolean) => {
    const [shallAutoScroll, setShallAutoScroll] = useState(isTactical);

    const disableAutoScroll = useCallback(() => {
        setShallAutoScroll(false);
    }, []);

    const enableAutoScroll = useCallback(() => {
        if (isTactical) {
            setShallAutoScroll(true);
        }
    }, [isTactical]);

    useEffect(() => {
        if (!isTactical) {
            setShallAutoScroll(false);
        }
    }, [isTactical]);

    useEffect(() => {
        window.addEventListener('wheel', disableAutoScroll, { capture: true });
        return () => {
            window.removeEventListener('wheel', disableAutoScroll, { capture: true });
        };
    }, [disableAutoScroll]);

    useEffect(() => {
        if (currentTimeIndicatorElement && shallAutoScroll) {
            currentTimeIndicatorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [shallAutoScroll, currentTimeIndicatorElement]);

    return { shallAutoScroll, enableAutoScroll, disableAutoScroll };
};
