import React, { memo, useState } from 'react';
import { BusUnavailabilityPeriodDialogContent } from './BusUnavailabilityPeriodDialogContent/BusUnavailabilityPeriodDialogContent';
import { BusUnavailabilityPeriodDialogActions } from './BusUnavailabilityPeriodDialogActions/BusUnavailabilityPeriodDialogActions';
import { UnavailabilityPeriodType } from '../BusUnavailabilityPeriodDialog';
import { DispatcherBusDto } from '../../../../../../backend/gen';

export const BusUnavailabilityPeriodDialogContentAndActionsContainer = memo(
    function BusUnavailabilityPeriodDialogContentAndActionsContainer({
        bus,
        closeDialog,
    }: {
        bus: DispatcherBusDto;
        closeDialog: () => void;
    }) {
        const now = new Date();
        const [period, setPeriod] = useState<UnavailabilityPeriodType>({
            startDate: bus.unavailability?.start ?? now.getTime(),
            startTime: bus.unavailability?.start ?? now.getTime(),
            endDate: bus.unavailability?.end ?? null,
            endTime: bus.unavailability?.end ?? null,
        });

        return (
            <>
                <BusUnavailabilityPeriodDialogContent period={period} setPeriod={setPeriod} />
                <BusUnavailabilityPeriodDialogActions
                    closeDialog={closeDialog}
                    period={period}
                    setPeriod={setPeriod}
                    bus={bus}
                />
            </>
        );
    },
);
