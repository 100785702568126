import { Box, styled } from '@mui/material';
import { ReactComponent as DepartureIcon } from '../../../../assets/icons/icon-aircraft-take-off.svg';
import { ReactComponent as ArrivalIcon } from '../../../../assets/icons/icon-aircraft-landing.svg';

export const StyledBusDisplayContentDepContainer = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 60px;
    color: white;
    font-size: 120px;
    line-height: 138px;
    font-weight: bold;
`;

export const StyledSideContainer = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const StyledLeftSideContainer = styled(StyledSideContainer)`
    align-items: flex-start;
`;

export const StyledRightSideContainer = styled(StyledSideContainer)`
    align-items: flex-end;
`;

export const StyledCenterContainer = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0;
    gap: 30px;
    img {
        padding: 0 60px;
    }
`;

export const StyledAirportNameContainer = styled(Box)`
    max-width: 830px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledAirportName = styled(Box)`
    margin-right: 120px;
`;

export const StyledTimeAndIcon = styled(Box)`
    display: flex;
    align-items: center;
    gap: 42px;
    width: 100%;
`;

export const StyledDashedLine = styled(Box)`
    border-top: 4px dashed white;
    height: 1px;
    width: 100%;
`;

export const StyledFlightNumber = styled(Box)`
    font-size: 54px;
    letter-spacing: 6px;
    font-weight: normal;
    padding-left: 18px;
`;

export const StyledTimeAtDestinationInfoText = styled(Box)`
    font-size: 42px;
    letter-spacing: 4.2px;
`;

export const StyledFlightInfoContainer = styled(Box)`
    min-height: 138px;
    display: flex;
    align-items: center;
`;

export const StyledFlightDelayed = styled(Box)`
    font-size: 54px;
    line-height: 62px;
    letter-spacing: 6px;
    font-weight: bold;
    padding: 4px 36px;
    background-color: ${({ theme }) => theme.palette.error.main};
`;

export const StyledDepartureIcon = styled(DepartureIcon)`
    min-width: 120px;
`;

export const StyledArrivalIcon = styled(ArrivalIcon)`
    min-width: 120px;
`;
